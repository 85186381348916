import React from "react";
import { Descriptions, Typography } from "antd";
const { Title } = Typography;

const AnswerBotKnowledgeFileDelete = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Purpose">Delete Knowledge File</Descriptions.Item>
        <Descriptions.Item label="Agent Name">{resource.agent_name}</Descriptions.Item>
        <Descriptions.Item label="Agent ID">{resource.agent_id}</Descriptions.Item>
        <Descriptions.Item label="Agent UUID">{resource.identifier}</Descriptions.Item>
        <Descriptions.Item label="File Name">{resource.file_name}</Descriptions.Item>
        {resource.file_id && (
          <Descriptions.Item label="File ID">{resource.file_id}</Descriptions.Item>
        )}
      </Descriptions>
    </>
  );
};

export default AnswerBotKnowledgeFileDelete;
