export const SBOsocialMediaChannels = [
  { label: 'FaceBook Business Profile', value: 'FaceBook Business Profile' },
  { label: 'LinkedIn Business Profile', value: 'LinkedIn Business Profile' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'Twitter', value: 'Twitter' },
  { label: 'Yelp', value: 'Yelp' },
  { label: 'YouTube', value: 'YouTube' },
];

export const SBOpresenceAttributes = [
  { label: 'Complete Full Company Name Search on Bing - Presence Acceptable', value: 'complete_full_search_bing' },
  { label: 'Complete Full Company Name Search on Google - Presence Acceptable', value: 'complete_full_search_google' },
  { label: 'Has Company claimed or set-up the Bing Business Profile?', value: 'claimed_business_profile' },
  { label: 'Does Company currently have pay-per-click advertising?', value: 'has_ppc_advertising' },
  { label: 'Does Company currently have an active SEO campaign?', value: 'has_active_seo' },
  { label: 'Does Company Manage Citations and Listings?', value: 'manages_citations_listings' },
];

export const ProductsWithETA = ['website_optimization', 'website_setup',  'website',  'lsa', 'saatchi', 'gbp_optimization',
                                'answer_bot_agent_setup', 'answer_bot_knowledge_file_upload', 'answer_bot_phone_setup',
                                'answer_bot_publish_agent', 'answer_bot_crawl_request', 'answer_bot_new_actions',
                                'answer_bot_google_calendar_integration', 'answer_bot_kb_file_removal'];
