import React from "react";
import { Descriptions, Typography } from "antd";
const { Title } = Typography;

const DudaWebsiteDevelopment = ({ resource }) => {
  const { client_details, website_hosting, website_pages, ai_rules, website_design } = resource.payload;

  function formatTrigger(trigger){
    switch(trigger){
      case 'time_and_date':
        return 'Time & Date';
      case 'number_of_visits':
        return 'Number of Visits';
      case 'devise_type':
        return 'Devise Type';
      case 'location':
        return 'Location';
      case 'campaign_url':
        return 'Campaign URL';
      case 'seasonal_personalization':
        return 'Seasonal Personalization';
      default:
        return trigger;
    }
  }

  function formatAction(action){
    switch(action){
      case 'display_new_content':
        return 'Display New Content';
      case 'show_notification_bar':
        return 'Show Notification Bar';
      case 'show_popup':
        return 'Show Popup';
      case 'display_special_effects':
        return 'Display Special Effects';
      default:
        return action;
    }
  }

  function formateWebsiteType(type){
    switch(type){
      case 'ai_basic':
        return 'Artificial Intelligence (AI)';
      case 'ai_ecom_standard':
        return 'AI with Ecommerce Standard (100 products)';
      case 'ai_ecom_advance':
        return 'AI with Ecommerce Advanced (2500 products)';
      case 'ai_ecom_unlimited':
        return 'AI with Ecommerce Unlimited (Unlimited products';
      default:
        return type;
    }
  }

  return (
    <>
      <Title level={4}>Order Information</Title>
      <div style={{ padding: '20px' }}>
        <Descriptions column={1} layout="horizontal">
          <Descriptions.Item label="Duda Site ID">{resource.duda_site_name}</Descriptions.Item>
        </Descriptions>
        {client_details && (
          <>
            <Title level={5} id="client_details">Client Details</Title>
            <Descriptions column={1} layout="vertical" size="small">
              <Descriptions.Item label="Client Email">{client_details.email}</Descriptions.Item>
              <Descriptions.Item label="Client First Name">{client_details.first_name}</Descriptions.Item>
              <Descriptions.Item label="Client Last Name">{client_details.last_name}</Descriptions.Item>
              <Descriptions.Item label="Company Name">{client_details.company_name}</Descriptions.Item>
            </Descriptions>
          </>
        )}

        {website_hosting && (
          <>
            <Title level={5} id="website_hosting" style={{ marginTop: '20px' }}>Website Hosting</Title>
            <Descriptions column={1} layout="vertical" size="small">
              <Descriptions.Item label="Website Type">{formateWebsiteType(website_hosting.website_type)}</Descriptions.Item>
              <Descriptions.Item label="Existing Website?">{website_hosting.existing_website}</Descriptions.Item>
              <Descriptions.Item label="Existing Domain?">{website_hosting.existing_domain}</Descriptions.Item>
              {
                website_hosting.existing_domain === 'yes' ? 
                <>
                  <Descriptions.Item label="Domain Name">{website_hosting.domain_name}</Descriptions.Item>
                  <Descriptions.Item label="Domain Provider">{website_hosting.domain_provider}</Descriptions.Item>
                  <Descriptions.Item label="Email/Username">{website_hosting.email_username}</Descriptions.Item>
                  <Descriptions.Item label="Password">{website_hosting.password}</Descriptions.Item>
                </> : website_hosting.existing_domain === 'purchase_for_me' ? 
                <>
                  <Descriptions.Item label="Requested Domain Name">{website_hosting.requested_domain_name}</Descriptions.Item>
                </> : null
              }

            </Descriptions>
          </>
        )}

        {website_pages && (
          <>
            <Title level={5} id="website_pages" style={{ marginTop: '20px' }}>Website Pages</Title>
            <Descriptions column={1} layout="vertical" size="small">
              <Descriptions.Item label="Number of Pages">{website_pages.number_of_pages}</Descriptions.Item>
            </Descriptions>
            <div style={{ paddingLeft: '20px', paddingTop: '20px' }}>
              <Descriptions column={2} layout="vertical" size="small">

              {website_pages.pages.map((page, index) => (
                <>
                  <Descriptions.Item key={index} label={`Page ${index + 1} Name`}>{page.name}</Descriptions.Item>
                  <Descriptions.Item key={index} label={`Page ${index + 1} Content`}>{page.content}</Descriptions.Item>
                </>
              ))}
              </Descriptions>
            </div>
          </>
        )}
        {ai_rules && (
          <>
            <Title level={5} id="ai_rules" style={{ marginTop: '20px' }}>AI Rules</Title>
            <Descriptions column={1} layout="vertical" size="small">
              <Descriptions.Item label="Number of AI Rules">{ai_rules.number_of_ai_rules}</Descriptions.Item>
            </Descriptions>
            <div style={{ paddingLeft: '20px', paddingTop: '20px' }}>
              <Descriptions column={3} layout="vertical" size="small">

              {ai_rules.rules.map((rule, index) => (
                <>
                  <Descriptions.Item key={index} label='Trigger'>{formatTrigger(rule.trigger)}</Descriptions.Item>
                  <Descriptions.Item key={index} label='Action'>{formatAction(rule.action)}</Descriptions.Item>
                  <Descriptions.Item key={index} label='Content'>{rule.content}</Descriptions.Item>
                </>
              ))}
              </Descriptions>
            </div>
          </>
        )}

        {website_design && (
          <>
            <Title level={5} id="website_hosting" style={{ marginTop: '20px' }}>Website Design</Title>
            <Descriptions column={1} layout="vertical" size="small">
              <Descriptions.Item label="Client's vision for style/vibe of website">{website_design.website_styling_vision}</Descriptions.Item>
              <Descriptions.Item label="Subtle or Vibrant Animations?">{website_design.website_animations}</Descriptions.Item>
              <Descriptions.Item label="Website Examples For Visual Reference">{website_design.website_examples}</Descriptions.Item>
              <Descriptions.Item label="Do you want any design elements replicated from an existing website?">{website_design.design_elements_replicated}</Descriptions.Item>
              <Descriptions.Item label="How different/similar do you want the new website to look from the existing website?">{website_design.visual_style_preference}</Descriptions.Item>
              <Descriptions.Item label="Specific Branding Instructions (Fonts, Colors, Image Style, Etc)">{website_design.branding_instructions}</Descriptions.Item>
              <Descriptions.Item label="Link to Any Images or Videos (Google Drive Link)">{website_design.images_videos_link}</Descriptions.Item>
              <Descriptions.Item label="Social Media Links">{website_design.social_media_links}</Descriptions.Item>
              <Descriptions.Item label="RSS Blog Feed URL (if applicable)">{website_design.rss_blog_feed_url}</Descriptions.Item>
              <Descriptions.Item label="Do you have a requested Publishing Date?">{website_design.publish_date}</Descriptions.Item>
            </Descriptions>
        </>
        )}
      </div>
    </>
  );
};

export default DudaWebsiteDevelopment;