import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { BASE_ORDER_ROUTES } from "../util/routes";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PageHeader from "./shared/PageHeader";
import { fetchOrders, 
         markAsComplete,
         markOrderAsCompleteClear } from "../actions/ordersActions";
import { Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import List from "./orders/List";
import cookie from 'react-cookies'

const TITLE = "Orders";

const Wrapper = styled.div`
  @media (max-width: 768px) {
    display: block;
    button {
      margin-left: 0.5em;
      &:first-of-type {
        margin-left: 0;
      }
    }
    span {
      &:first-child {
        margin-bottom: 0.5em;
      }
    }
  }
`;

class Orders extends React.Component {
  state = {
    page: 1,
    pageSize: cookie.load('VendorOrderPageSize') || 25,
    filters: {},
    sorter: {},
    query: "",
  };

  componentDidMount() {
    let { filters, sorter, pageSize, query } = this.state;
    this.setState({ query: '', page: 1 });
    this.props.dispatch(fetchOrders(1, pageSize, filters, sorter, query));
  }


  componentDidUpdate(prevProps, _prevState) {
    let { filters, sorter, pageSize, query } = this.state;
    if (this.props.markOrderAsCompleteSuccess) {
      message.success("Order has been marked as completed");
      this.props.dispatch(markOrderAsCompleteClear());
      this.props.dispatch(fetchOrders(1, pageSize, filters, sorter, query));
    }
  }

  handleMarkOrderAsComplete = async (id) => {
    this.props.dispatch(markAsComplete(id));
  };

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current;
    const pageSize = pagination.pageSize;
    cookie.save('VendorOrderPageSize', pageSize)
    let { query } = this.state;
    this.props.dispatch(fetchOrders(page, pageSize, filters, sorter, query));
    this.setState({
      page,
      filters,
      sorter,
      pageSize,
    });
  };

  handleSearch = _.debounce((query) => {
    let { filters, sorter, pageSize } = this.state;
    this.setState({ query, page: 1 });
    this.props.dispatch(fetchOrders(1, pageSize, filters, sorter, query));
  }, 300);

  render() {
    const { orders, totalItems, loading } = this.props;
    const { page, pageSize } = this.state;

    return (
      <>
        <PageHeader title={TITLE} routes={BASE_ORDER_ROUTES} />
        <div className="content-holder" style={{ background: "#fff" }}>
          <Wrapper className="button-action-bar">
            <Input
              placeholder="Search by client, member, kind, status or order ID"
              onChange={(e) => this.handleSearch(e.target.value)}
              suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            />
          </Wrapper>
          <List
            orders={orders}
            totalOrders={totalItems}
            onTableChange={this.handleTableChange}
            loading={loading}
            page={page}
            markAsComplete={this.handleMarkOrderAsComplete}
            pageSize={pageSize}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.orders.items,
  totalItems: state.orders.totalItems,
  loading: state.orders.loading,
  error: state.orders.error,
  markOrderAsCompleteSuccess: state.orders.markOrderAsCompleteSuccess,
});

export default connect(mapStateToProps)(withRouter(Orders));
